<template>
  <page-layout title="Balises SPOT">
    <template v-slot:breadcrumb>
      <li><router-link :to="{ name: 'home' }">Dashboard</router-link></li>
      <li class="is-active"><a href="#">Balises SPOT</a></li>
    </template>
    <list-spot-trackers-action :loading="loading" :spotTrackers="spotTrackers" @import="submit" />
    <o-message :errorMessages="importErrorMessages" title="Erreurs d'importation" />
    <list-spot-trackers-filter />
    <list-spot-trackers-table :spotTrackers="spotTrackers" :loading="loading" class="box" />
  </page-layout>
</template>

<script>
import OMessage from '@components/Message.vue'
import PageLayout from '@components/PageLayout.vue'
import ListSpotTrackersTable from './ListSpotTrackersTable.vue'
import ListSpotTrackersFilter from './ListSpotTrackersFilter.vue'
import ListSpotTrackersAction from './ListSpotTrackersAction.vue'
import { getErrorMessages, importOwnerSpotTrackerError } from '@helpers/importErrorMessages'
import { mapActions } from 'vuex'

export default {
  name: 'ListSpotTrackersPage',

  components: {
    ListSpotTrackersAction,
    ListSpotTrackersFilter,
    ListSpotTrackersTable,
    [PageLayout.name]: PageLayout,
    OMessage,
  },

  data() {
    return {
      loading: true,
      spotTrackers: [],
      importErrorMessages: [],
    }
  },

  metaInfo() {
    return {
      title: 'Balises SPOT',
    }
  },

  async beforeRouteUpdate(to, from, next) {
    await this.fetch(to.query)
    next()
  },

  async mounted() {
    await this.fetch(this.$route.query)
  },

  methods: {
    ...mapActions('ui', ['addToastMessage']),

    async fetch(filters) {
      this.loading = true

      try {
        this.spotTrackers = await this.$services.spotTrackerService.getAll({
          keyword: filters?.keyword ?? null,
          availability: filters?.availability ?? null,
          owner: filters?.owner ?? null,
          expired: filters?.expired ?? null,
        })
      } catch (err) {
        console.error(err)
      }

      this.loading = false
    },

    async submit({ result, error }) {
      if (error) {
        this.importErrorMessages = [error]

        return
      }

      this.importErrorMessages = []
      this.loading = true

      try {
        const updatedSpotTrackers = await this.$services.spotTrackerService.updateAll({
          spotTrackers: this.formatSpotTrackersData(result),
        })

        this.updateSpotTrackersList(updatedSpotTrackers)

        this.addToastMessage({
          text: 'Les propriétaires des spots ont été modifiés',
          type: 'is-success',
        })
      } catch (err) {
        this.importErrorMessages = getErrorMessages(err, importOwnerSpotTrackerError)
      }

      this.loading = false
    },

    formatSpotTrackersData(data) {
      return data.map((spotTracker) => ({
        ...spotTracker,
        owner: spotTracker.owner.toLowerCase(),
      }))
    },

    updateSpotTrackersList(updatedSpotTrackers) {
      this.spotTrackers = [
        ...updatedSpotTrackers,
        ...this.spotTrackers.filter((spot) => {
          const ids = new Set(updatedSpotTrackers.map((s) => s.id))
          return !ids.has(spot.id)
        }),
      ]
    },
  },
}
</script>
