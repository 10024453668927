<template>
  <form novalidate="true" @submit.prevent="submit">
    <b-notification type="is-danger" :active="error !== null" :closable="false">
      {{ error }}
    </b-notification>
    <b-field group-multiline grouped class="columns">
      <b-field
        label="Disponibilité"
        :message="fields.availability.error"
        :type="fields.availability.error !== null ? 'is-danger' : ''"
        class="column is-full-mobile"
      >
        <b-input v-if="isUnavailable" disabled :value="unavailable" />
        <b-select
          v-else
          v-model="fields.availability.value"
          label="Choisir une disponibilité"
          expanded
        >
          <option
            v-for="availability in Object.keys(availabilities)"
            :key="availability"
            :value="availability"
          >
            {{ availabilities[availability] }}
          </option>
        </b-select>
      </b-field>
      <b-field
        label="Propriétaire"
        :message="fields.owner.error"
        :type="fields.owner.error !== null ? 'is-danger' : ''"
        class="column is-full-mobile"
      >
        <b-select v-model="fields.owner.value" label="Choisir un propriétaire" expanded>
          <option v-for="owner in owners" :key="owner" :value="owner">
            {{ capitalize(owner) }}
          </option>
        </b-select>
      </b-field>
    </b-field>
    <b-field
      expanded
      label="Date d'expiration"
      :message="fields.expiredAt.error"
      :type="fields.expiredAt.error !== null ? 'is-danger' : ''"
    >
      <b-datepicker
        v-model="fields.expiredAt.value"
        placeholder="Type or select a date..."
        icon="calendar-day"
        :date-formatter="dateFormat"
      />
    </b-field>
    <b-field
      label="Commentaire"
      :message="fields.comment.error"
      :type="fields.comment.error !== null ? 'is-danger' : ''"
    >
      <b-input v-model="fields.comment.value" type="textarea" />
    </b-field>
    <hr />
    <b-field>
      <b-button native-type="submit" type="is-primary" :loading="loading"> Modifier </b-button>
    </b-field>
  </form>
</template>

<script>
import { format } from 'date-fns'
import { mapActions } from 'vuex'
import { capitalize } from '@helpers/capitalize'
import { AVAILABILITIES, AVAILABILITY_UNAVAILABLE } from '@constants/tracker/availability.js'
import { OWNERS } from '@constants/tracker/owner.js'

export default {
  name: 'SpotTrackerEditForm',

  props: {
    spotTracker: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      error: null,
      loading: false,
      fields: {
        availability: {
          value: '',
          error: null,
        },
        expiredAt: {
          value: null,
          error: null,
        },
        comment: {
          value: '',
          error: null,
        },
        owner: {
          value: '',
          error: null,
        },
      },
    }
  },

  watch: {
    spotTracker: {
      handler: 'load',
      immediate: true,
    },
  },

  created() {
    this.owners = OWNERS
    this.unavailable = AVAILABILITIES[AVAILABILITY_UNAVAILABLE]
    this.availabilities = Object.fromEntries(
      Object.entries(AVAILABILITIES).filter(([key]) => key !== AVAILABILITY_UNAVAILABLE),
    )
  },

  computed: {
    isUnavailable() {
      return this.spotTracker.availability === AVAILABILITY_UNAVAILABLE
    },
  },

  methods: {
    ...mapActions('ui', ['addToastMessage']),

    load() {
      this.fields.availability.value = this.spotTracker.availability
      this.fields.expiredAt.value = this.spotTracker.expiredAt
      this.fields.comment.value = this.spotTracker.comment
      this.fields.owner.value = this.spotTracker.owner
    },

    async submit() {
      this.error = null
      this.loading = true

      try {
        const spotTracker = await this.$services.spotTrackerService.update(this.spotTracker.id, {
          availability:
            this.fields.availability.value !== AVAILABILITY_UNAVAILABLE
              ? this.fields.availability.value
              : null,
          expiredAt: this.fields.expiredAt.value,
          comment: this.fields.comment.value,
          owner: this.fields.owner.value,
        })
        this.$emit('update:spotTracker', spotTracker)
        this.addToastMessage({
          text: 'Les modifications ont bien été enregistré.',
          type: 'is-success',
        })
      } catch (err) {
        this.error = 'Une erreur interne est survenue.'
        console.error(err)
      }

      this.loading = false
    },

    dateFormat(date) {
      return format(date, 'dd/MM/yyyy')
    },

    capitalize(value) {
      return capitalize(value)
    },
  },
}
</script>
