<template>
  <o-table :items="lives" :loading="loading" :draggable="false">
    <b-table-column v-slot="{ row }" field="name" label="Nom">
      <router-link :to="{ name: 'live.edit.informations', params: { id: row.id } }">
        {{ row.name }}
      </router-link>
    </b-table-column>
    <b-table-column v-slot="{ row }" field="vehicleNumber" label="Dossard">
      <router-link
        :to="{
          name: 'live.edit.vehicle.edit',
          params: { id: row.id, liveVehicleId: row.vehicleId },
        }"
      >
        {{ row.vehicleNumber }}
      </router-link>
    </b-table-column>
    <b-table-column v-slot="{ row }" field="startedAt" label="Début">
      {{ formatDate(row.startedAt) }}
    </b-table-column>
    <b-table-column v-slot="{ row }" field="endedAt" label="Fin">
      {{ formatDate(row.endedAt) }}
    </b-table-column>
  </o-table>
</template>

<script>
import { format } from 'date-fns'
import OTable from '@components/Table.vue'

export default {
  name: 'SpotTrackerLiveTable',

  components: {
    OTable,
  },

  props: {
    lives: {
      type: Array,
      required: true,
      validator: (v) =>
        v.every((obj) =>
          ['id', 'name', 'vehicleId', 'vehicleNumber', 'startedAt', 'endedAt'].every(
            (key) => key in obj,
          ),
        ),
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },

  methods: {
    formatDate(date) {
      return !date ? 'Aucune date' : format(date, 'dd/MM/yyyy')
    },
  },
}
</script>
