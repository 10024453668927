<template>
  <form novalidate="true" @submit.prevent="submit">
    <b-field
      label="Numéro"
      :message="fields.number.error"
      :type="fields.number.error !== null ? 'is-danger' : ''"
    >
      <b-input v-model="fields.number.value" type="text" />
    </b-field>
    <b-field
      label="ESN"
      :message="fields.serialNumber.error"
      :type="fields.serialNumber.error !== null ? 'is-danger' : ''"
    >
      <b-input v-model="fields.serialNumber.value" type="text" />
    </b-field>
    <b-field
      label="Auth"
      :message="fields.authorizationCode.error"
      :type="fields.authorizationCode.error !== null ? 'is-danger' : ''"
    >
      <b-input v-model="fields.authorizationCode.value" type="text" />
    </b-field>
    <b-field
      label="Date d'achat"
      :message="fields.purchasedAt.error"
      :type="fields.purchasedAt.error !== null ? 'is-danger' : ''"
    >
      <b-datepicker
        v-model="fields.purchasedAt.value"
        placeholder="Type or select a date..."
        icon="calendar-day"
        :date-formatter="dateFormat"
      />
    </b-field>
    <b-field
      label="Propriétaire"
      :message="fields.owner.error"
      :type="fields.owner.error !== null ? 'is-danger' : ''"
    >
      <b-select v-model="fields.owner.value" label="Choisir un propriétaire" expanded>
        <option v-for="owner in owners" :key="owner" :value="owner">
          {{ capitalize(owner) }}
        </option>
      </b-select>
    </b-field>
    <b-field
      label="Commentaire"
      :message="fields.comment.error"
      :type="fields.comment.error !== null ? 'is-danger' : ''"
    >
      <b-input v-model="fields.comment.value" type="textarea" />
    </b-field>
    <hr />
    <b-field>
      <b-button native-type="submit" type="is-primary" :loading="loading"> Ajouter </b-button>
    </b-field>
  </form>
</template>

<script>
import { mapActions } from 'vuex'
import { format } from 'date-fns'
import { OWNERS } from '@constants/tracker/owner'
import { capitalize } from '@helpers/capitalize'

export default {
  name: 'AddSpotTrackerForm',

  data() {
    return {
      loading: false,
      fields: {
        number: {
          value: '',
          error: null,
        },
        serialNumber: {
          value: '',
          error: null,
        },
        authorizationCode: {
          value: '',
          error: null,
        },
        purchasedAt: {
          value: null,
          error: null,
        },
        owner: {
          value: '',
          error: null,
        },
        comment: {
          value: '',
          error: null,
        },
      },
    }
  },

  created() {
    this.owners = OWNERS
  },

  methods: {
    ...mapActions('ui', ['addToastMessage']),

    async submit() {
      this.error = null
      this.loading = true

      try {
        const spotTracker = await this.$services.spotTrackerService.create({
          number: this.fields.number.value,
          serialNumber: this.fields.serialNumber.value,
          authorizationCode: this.fields.authorizationCode.value,
          purchasedAt: this.fields.purchasedAt.value,
          comment: this.fields.comment.value,
          owner: this.fields.owner.value.toLowerCase(),
        })
        this.addToastMessage({
          text: `Le tracker Spot ${spotTracker.number} a été ajouté.`,
          type: 'is-success',
        })
        this.$router.push({
          name: 'spotTracker.edit',
          params: { id: spotTracker.id },
        })
      } catch (err) {
        this.error = 'Une erreur interne est survenue.'
        console.error(err)
      }

      this.loading = false
    },

    dateFormat(date) {
      return format(date, 'dd/MM/yyyy')
    },

    capitalize(value) {
      return capitalize(value)
    },
  },
}
</script>
