<template>
  <page-layout :title="title">
    <template v-slot:breadcrumb>
      <li><router-link :to="{ name: 'home' }">Dashboard</router-link></li>
      <li>
        <router-link :to="{ name: 'spotTracker.list' }">Balises SPOT</router-link>
      </li>
      <li class="is-active">
        <a href="#">{{ title }}</a>
      </li>
    </template>
    <div class="box">
      <add-spot-tracker-form />
    </div>
  </page-layout>
</template>

<script>
import PageLayout from '@components/PageLayout.vue'
import AddSpotTrackerForm from './AddSpotTrackerForm.vue'

export default {
  name: 'AddSpotTrackerPage',

  components: {
    [PageLayout.name]: PageLayout,
    [AddSpotTrackerForm.name]: AddSpotTrackerForm,
  },

  data() {
    return {
      title: 'Ajouter une balise SPOT',
    }
  },
}
</script>
