<template>
  <b-field label="Accéder à une balise SPOT">
    <b-autocomplete
      :data="spotTrackers"
      placeholder="Numéro ou ESN"
      :loading="loading"
      @typing="getSpotTrackers"
      @select="redirect"
    >
      <template v-slot="props">
        <div class="media">
          <div class="media-content">
            Balise SPOT #{{ props.option.number }}
            <br />
            <small>{{ `ESN : ${props.option.serialNumber}` }}</small>
          </div>
        </div>
      </template>
    </b-autocomplete>
  </b-field>
</template>

<script>
export default {
  name: 'SpotTrackerSearch',

  data() {
    return {
      loading: false,
      timeoutId: null,
      spotTrackers: [],
    }
  },

  methods: {
    getSpotTrackers(input) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId)
        this.timeoutId = null
      }

      const keyword = input?.trim().toUpperCase()
      if (keyword.length < 2) {
        this.spotTrackers = []
        return
      }

      this.timeoutId = setTimeout(async () => {
        await this.fetch(keyword)
      }, 500)
    },

    async fetch(keyword) {
      this.loading = true

      try {
        this.spotTrackers = await this.$services.spotTrackerService.getAll({ keyword })
      } catch (err) {
        this.spotTrackers = []
        console.error(err)
      }

      this.loading = false
    },

    async redirect(spotTracker) {
      if (spotTracker.id === this.$route.params.id) {
        return
      }

      await this.$router.push({ name: 'spotTracker.edit', params: { id: spotTracker.id } })
    },
  },
}
</script>
