<template>
  <map-view :map-center="mapCenter" class="spot-tracker-map">
    <o-map-square-marker
      v-if="spotTrackerLocation"
      :label="label"
      iconName="map-marker"
      color="blue"
      :lat-lng="spotTrackerLocation.location"
    />
  </map-view>
</template>

<script>
import { format } from 'date-fns'
import MapView from '@views/MapView/index.vue'
import OMapSquareMarker from '@components/Map/MapSquareMarker.vue'

export default {
  name: 'SpotTrackerMap',

  components: {
    MapView,
    OMapSquareMarker,
  },

  props: {
    id: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      spotTrackerLocation: null,
    }
  },

  watch: {
    id: {
      handler: 'load',
      immediate: true,
    },
  },

  computed: {
    mapCenter() {
      return this.spotTrackerLocation ? this.spotTrackerLocation.location : [0, 0]
    },

    label() {
      return this.spotTrackerLocation
        ? `Dernière localisation le ${format(
            new Date(this.spotTrackerLocation.locatedAt),
            'dd/MM/yyyy HH:mm',
          )}`
        : ''
    },
  },

  methods: {
    async load() {
      try {
        this.spotTrackerLocation = await this.$services.spotTrackerService.getLatestLocation(
          this.id,
        )
      } catch (err) {
        this.spotTrackerLocation = null
      }
    },
  },
}
</script>

<style lang="scss">
@import 'variables';
$map-view-min-height: 300px;

.spot-tracker-map {
  height: 100%;
  min-height: $map-view-min-height;
}
</style>
